export const User = {
  Auth: '/user/auth',
  Logout: '/user/logout',
  GoogleAuth: '/user/googleauth',
  FacebookAuth: '/user/facebookauth',
  RefreshToken: '/user/refresh',
  Register: '/user/registerclient',
  ForgotPasswordSendCode: '/user/forgotpwdsendcode',
  ForgotPasswordConfirmCode: '/user/forgotpwdconfirm',
  ResetPassword: '/user/resetpassword',
  UpdateETransferAutoRequest: '/user/updateetransferautorequest',
  RevokeAccess: '/user/revoke',
  ConfirmEmail: '/user/confirmemail',
  ResendEmailConfirmationCode: '/user/resendconfirm',
  UpdateUser: '/user/update',
  DecryptConfirmationToken: '/user/decryptconfirmationtoken',
  List: '/user/list',
  Remove: '/user/remove',
  GetById: '/user/get',
  DeleteAccount: '/user/deleteaccount',
}

export const Client = {
  Update: '/client/update',
  GetByUserId: '/client/getbyuserid',
  GetWalletByUserId: '/client/getwalletbyuserid',
  GetWalletMinimum: '/client/getwithdrawminimum',
  GetRequestRules: '/client/getrequestrules',
  GetCharities: '/client/getauthorizedcharities',
  SendToCharity: '/client/sendToCharity',
  GetCustomerIdWallet: '/client/getCustomerIdWallet',
  GetTransactions: '/client/gettransactions',
  RequestTransfer: '/client/requestetransfer',
  RequestCheque: '/client/requestcheque',
  RequestCash: '/client/requestcash',
  GetWalletTransactions: '/client/getwallettransactions',
  GetETransfersByClient: '/client/getetransfersbyclient',
  GetRedemptionsByClient: '/client/getredemptionsbyclient',
  GetWalletTransactionsByDate: '/client/getwallettransactionsbydate',
  HasPendingEtransfer: '/client/haspendingetransfer',
  GetDonations: '/client/getdonations',
  GetDashboard: '/client/getdashboard',
  GetOffers: '/client/getoffers',
  GetRecurrenceSettings: '/client/getrecurrencesettings',
  CancelRecurrence: '/client/cancelrecurrence',
  GetDepotListForCashRedemption: '/client/getdepotlistforcashredemption',
}

export const Depot = {
  RegisterDepot: '/depot/register',
  PostalCodeList: '/depot/postalcodelist',
  SavePostalCode: '/depot/savepostalcode',
  SelectList: '/depot/selectlist',
  DepotList: '/depot/list',
  DepotMapList: '/depot/listmap',
  DisableDepot: '/depot/disable',
  EnableDepot: '/depot/enable',
  GetById: '/depot/getbyid',
  Update: '/depot/update',
  GetDepotInCoverage: '/depot/getdepotincoverage',
  GetPromocode: '/depot/getpromocode',
  GetBottleDrivesByDepot: '/depot/getbottledrivesbydepot',
  GetBottleDrives: '/depot/getbottledrives',
  SendToBottleDrive: '/depot/sendTobottledrive',
  SendReferralVerificationCode: '/depot/sendreferralverificationcode',
  VerifyReferralVerificationCode: '/depot/verifyreferralverificationcode',
}

export const Config = {
  GetDaysLimit: '/config/getdayslimit',
  GetPercentageLimit: '/config/getpercentagelimit',
  GetThresholdInfo: '/config/getthresholdinfo',
  GetEtransferFee: '/config/getetransferfee',
  GetChequeFee: '/config/getchequefee',
  GetCashFee: '/config/getcashfee',
  GetMyPopups: '/config/getmypopups',
  GetReferralConfig: '/config/getreferralconfig',
}

export const Schedule = {
  GetLimits: '/schedule/getlimits',
}

export const Order = {
  CreateOrder: '/order/createorder',
  UpdateOrder: '/order/updateorder',
  GetOrders: '/order/getorders',
  GetById: '/order/getbyid',
  CancelOrder: '/order/cancelorder',
  GetImpactReport: '/order/getimpactreport',
}
